import GroupImage from '../../assets/imgs/clients-group.png';
import Client1 from '../../assets/imgs/client-1.png';
import Client2 from '../../assets/imgs/client-2.png';
import Client3 from '../../assets/imgs/client-3.jpg';

import './clients.styles.scss';

const Clients = () => {
  return (
    <div className="clients-container">
      <img src={GroupImage} alt="Clients images" className="group-image" />
      
      <div className="individual-images">
        <img src={Client1} alt="Client 1" />
        <img src={Client2} alt="Client 2" />
        <img src={Client3} alt="Client 3" />
      </div>
      
      <div className="clients-text">
        <p className='clients-sub-title'>
          Permítanos ser su aliado <span className='title-highlighted'>estratégico</span> en la protección de su
          equipo y <span className='title-highlighted'>el cumplimiento normativo.</span>
        </p>
        <p className=''>
          En Proworking, combinamos nuestra amplia experiencia y compromiso para ser su aliado 
          estratégico en la gestión de la Seguridad y Salud en el Trabajo.
          Nuestro equipo 
          multidisciplinario de expertos, respaldado por una red de cobertura nacional, 
          está dedicado a proporcionar soluciones integrales y personalizadas que garanticen 
          un entorno laboral seguro y saludable.
        </p>
      </div>
    </div>
  );
}

export default Clients;

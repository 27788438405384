import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import FormImage from '../../assets/imgs/form-img.webp';
import './contactForm.styles.scss';

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    ).then((result) => {
      console.log(result.text);
      alert('Datos enviados exitosamente.');
      form.current.reset();
    }, (error) => {
      console.log(error.text);
      alert('Hubo un error al enviar el correo.');
    });
  }

  return (
    <div className="contact-form-container">
      <img src={FormImage} alt="Form image" className="contact-form-image" />
      <div className="contact-form-content">
        <h2>Envíanos un mensaje</h2>
        <p>y un asesor se pondrá en contacto</p>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="user_name" placeholder="Nombre o Razón Social" required />
          <select name="user_type" required defaultValue="">
            <option value="" disabled hidden>Tipo de persona</option>
            <option>Natural</option>
            <option>Jurídica</option>
          </select>
          <select name="id_type" required defaultValue="">
            <option value="" disabled hidden>Tipo de identificación</option>
            <option>Cédula</option>
            <option>NIT</option>
          </select>
          <input type="text" name="id_number" placeholder="No. de Identificación" pattern="[0-9]+" required />
          <input 
            type="tel" 
            name="user_phone"
            placeholder="Teléfono" 
            required 
            pattern="[0-9]+" 
            inputMode="numeric" 
          />
          <input 
            type="email" 
            name="user_email" 
            placeholder="Correo Electrónico" 
            required 
          />
          <textarea name="message" placeholder="Mensaje" required></textarea>
          <button type="submit">Enviar mensaje</button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;

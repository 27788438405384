import React, { useState } from 'react';
import './companyPortal.styles.scss';

const CompanyPortal = () => {
  const [currentPortal, setCurrentPortal] = useState('portal1');
  const [animationDirection, setAnimationDirection] = useState('left');

  const portals = {
    portal1: {
      url: "https://app.biofile.com.co/api/integracionweb/Z3kODT4FgF4v3O0VmtVn6ikBgvByMPUasbpkCsbmrgE=",
      name: "Portal Proworking"
    },
    portal2: {
      url: "https://app.biofile.com.co/api/integracionweb/Sw8ibAj3Db8smxR|BZh99NwDiRVy7e180ilN1MpOlSI=",
      name: "Portal Prowork"
    }
  };

  const handlePortalChange = (portal) => {
    if (portal !== currentPortal) {
      setAnimationDirection(portal === 'portal1' ? 'left' : 'right');
      setCurrentPortal(portal);
    }
  };

  return (
    <div className='company-portal-container'>
      <div className="portal-buttons">
        <button 
          onClick={() => handlePortalChange('portal1')}
          className={currentPortal === 'portal1' ? 'active' : ''}
        >
          Portal Proworking
        </button>
        <button 
          onClick={() => handlePortalChange('portal2')}
          className={currentPortal === 'portal2' ? 'active' : ''}
        >
          Portal Prowork
        </button>
      </div>
      <div className='portal-name'>
        {portals[currentPortal].name}
      </div>
      <div className={`iframe-container ${animationDirection}`}>
        <iframe 
          src={portals[currentPortal].url}
          width='100%'
          height='1200px'
          scrolling='auto'
          frameBorder="0">
        </iframe>
      </div>
    </div>
  );
}

export default CompanyPortal;

